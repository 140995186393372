function trackEvent( event, pCategory, pSpecificLabel )
{
    pSpecificLabel = pSpecificLabel || null;

    window.dataLayer.push({
        event: 'event',
        origin: 'website',
        eventCategory: pCategory.replace( /^([a-z])/, r => r.toUpperCase() ),
        eventAction: event,
        eventLabel: pSpecificLabel || event.target.innerHTML
    });
}

export default {
    methods: {
        trackEvent
    }
};
