import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/views/Landing' );
            }
        },
        {
            path: '/uitleg',
            component: () =>
            {
                return import( /* webpackChunkName: "explain" */ '@/views/Explain' );
            }
        },
        {
            path: '/game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/views/Game' );
            }
        },
        {
            path: '/gegevens',
            component: () =>
            {
                return import( /* webpackChunkName: 'form' */ '@/views/Naw' );
            },
            beforeEnter: ( to, from, next ) =>
            {
                if( to.name === 'FullForm' )
                {
                    next( '/form/1' );
                }
                else
                {
                    next();
                }
            },
            children: [
                {
                    path: '1',
                    name: 'naw1',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/views/naw/Form1' );
                    }
                },

                {
                    path: '2',
                    name: 'naw2',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/views/naw/Form2' );
                    }
                },

                {
                    path: '3',
                    name: 'naw3',
                    component: () =>
                    {
                        return import( /* webpackChunkName: 'form1' */ '@/views/naw/Form3' );
                    }
                }
            ]
        },
        {
            path: '/bedankt',
            component: () =>
            {
                return import( /* webpackChunkName: "thanks" */ '@/views/Thanks' );
            }
        },
    ]
});

router.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default router;
