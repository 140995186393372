<template>
    <ynk-layout>
        <svg style="display:none">
            <symbol viewBox="0 0 11 6" id="icon-chevron"><path d="M10.146.146a.5.5 0 0 1 .708.708l-5 5a.5.5 0 0 1-.708 0l-5-5A.5.5 0 0 1 .854.146L5.5 4.793 10.146.146z"></path></symbol>
            <symbol viewBox="0 0 19 12" id="icon-arrow-right"><path d="M12.646 1.354a.5.5 0 0 1 .708-.708l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L17.293 6l-4.647-4.646z"></path><path d="M1 6.5a.5.5 0 0 1 0-1h16a.5.5 0 1 1 0 1H1z"></path></symbol>
        </svg>
        <router-view v-slot="{ Component, route }" >
            <component :is="Component" ref="currentView" :key="route.path" />
        </router-view>
    </ynk-layout>
</template>

<style lang="scss">
    @import './scss/app.scss';
</style>

<script>
    import Main from '@/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            }
        }
    };
</script>
