import Axios from 'axios';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import PIXI from './PIXI';

gsap.registerPlugin( MotionPathPlugin );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

export {
    gsap,
    Axios,
    PIXI
};
