function dottify( pNumber )
{
    if( !pNumber )
    {
        return;
    }

    while( /(\d+)(\d{3})/.test( pNumber.toString() ) )
    {
        pNumber = pNumber.toString().replace( /(\d+)(\d{3})/, '$1.$2' );
    }

    return pNumber;
}

export default {
    methods: { dottify }
};
