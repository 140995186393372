import { createStore } from 'vuex';

import entry from './modules/entry';
import game from './modules/game';

export default createStore({

    modules: {
        entry,
        game
    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
